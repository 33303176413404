import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/:key',
    name: 'home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/app-authority/index',
    name: 'authority',
    component: () => import('@/views/app-authority/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
